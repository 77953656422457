import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Layout from '../layout';
import PostCardList from '../components/postCardLists/postCardList';
import SEO from '../components/SEO';
import SubscribeForm from '../components/subscribe/SubscribeForm';
import FeaturedPost from '../components/featuredPost';
import { HeaderSimple } from '../components/headers';

import ImageQuote from '../components/images/ImageQuote';
import LandingImageOne from '../components/images/LandingImageTwo';
import BgVideo from '../components/bgVideo';

import LandingImageThree from '../components/images/LandingImageThree';
import '../components/style.css';
import LogoReel from '../components/images/logoreel';
import BgVideoTestimonial from '../components/bgVideoTestimonial';
import Slider from '../components/slider/Slider';
import ItImage from '../components/images/ItImage';
import Clients from '../components/clients';
import Quote from '../components/Quotes';
import BookDiscoveryCall from '../components/BookDiscoveryCall';

const PostList = ({ data }) => {
  const { edges } = data.allMarkdownRemark;
  const postList = [];
  edges.forEach(edge => {
    postList.push({
      path: edge.node.fields.slug,
      tags: edge.node.frontmatter.tags,
      category: edge.node.frontmatter.category,
      cover: edge.node.frontmatter.cover,
      title: edge.node.frontmatter.title,
      date: edge.node.frontmatter.date,
      timeToRead: edge.node.timeToRead,
      excerpt: edge.node.excerpt,
    });
  });
  return (
    <Layout>
      <SEO />
      <div className="col-md-12 p-0 mx-auto">
        <div className="container-fluid px-0">
          <div className=" pt-1">
            <BgVideo />
          </div>
        </div>
        <Slider />
        <div className="container pb-5">
          <div className="row p-4">
            <p className="text-center col-md-12 text-muted text-uppercase font-weight-bold mt-5 inline-block px-3 py-5">
              <span className="p-3 bg-themeSecondary m-3" />
              What we do
            </p>
            <div className="col-md-12 text-center align-self-center text-left">
              <div className="bg-hover link-what-we-do ">
                <Link className="text-neutralPrimary" to="/information-technology">
                  <h4 className="p-md-4 p-2 display-3 link-white-hover mt-0">IT and Systems</h4>
                </Link>
              </div>
              <div className="bg-hover link-what-we-do ">
                <Link className="text-neutralPrimary" to="/web-development">
                  <h4 className="p-md-4 p-2 display-3 link-white-hover  mt-0">Web Development</h4>
                </Link>
              </div>
              <div className="bg-hover link-what-we-do ">
                <Link className="text-neutralPrimary" to="/digital-services">
                  <h4 className="p-md-4 p-2 display-3 link-white-hover  mt-0">Digital Design</h4>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid  bg-neutralLighter text-left px-0">
          <div className="row m-0 py-5 px-3 p-md-5">
            <div className="container">
              <h2 className=" intro align-self-center font-weight-light  text-neutralTertiary">
                Solutions in web development, automation, lead generation, and platform integration. Allowing you to
                focus on running your business whilst we bring you results
              </h2>
            </div>
          </div>
          <Clients clientImage={<ItImage />} text=" We Make Digital and IT Simple" />
          <Quote
            video={<BgVideoTestimonial />}
            name="Andile Siziba"
            job="Director of Neurophysiology | MediServices Healthcare"
            quoteOne="Since approaching Onai Intelligence to build our website and re-brand our business, we have since seen an
                increase in our digital ROI."
            quoteTwo=" Onai has since extended our website to include other processes as well as their other Digital, IT and
                SEO services. I would highly recommend Onai to any small business"
          />
        </div>
        {/* <div className="row m-0"> */}
        {/*  /!* <FeaturedPost /> *!/ */}
        {/*  <div className="col-md-12 px-0 pb-4"> */}
        {/*    <p className="text-center col-md-12 text-muted text-uppercase font-weight-bold mt-5 inline-block px-3 py-5"> */}
        {/*      <span className="p-3 bg-themeSecondary m-3" /> */}
        {/*      Articles */}
        {/*    </p> */}
        {/*    <PostCardList posts={postList} /> */}
        {/*    <div className="col-md-12 p-4 text-center align-self-center p-4"> */}
        {/*      <Link to="/blog" className="btn btn-outline-dark mx-auto text-center rounded-0 "> */}
        {/*        Read more now &rarr; */}
        {/*      </Link> */}
        {/*    </div> */}
        {/*  </div> */}
        {/*  /!* <PostPagination currentPage={currentPage} numPages={numPages} /> *!/ */}
        {/* </div> */}
        <BookDiscoveryCall />
      </div>
    </Layout>
  );
};

PostList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }).isRequired,
            excerpt: PropTypes.string.isRequired,
            timeToRead: PropTypes.number.isRequired,
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
              tags: PropTypes.arrayOf(PropTypes.string),
              cover: PropTypes.object,
              category: PropTypes.string,
              date: PropTypes.string,
            }).isRequired,
          }).isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }).isRequired,
};

export default PostList;

export const pageQuery = graphql`
  query PostListQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 6) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 100)
          timeToRead
          frontmatter {
            title
            tags
            cover {
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 1080) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "MMMM DD YYYY")
            category
          }
        }
      }
    }
  }
`;
